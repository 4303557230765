import { useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import Link from 'next/link';

import { formatAuthError } from '@util/index';
import { emailSchema, passwordSchema, getUserById, createUserDocument, getValidUserDocument } from '@util/firestore/users';
import AuthProvider, { useAuth } from 'context/AuthContext';

import Button from '@ui/Button';
import FormInput from '../forms/controls/FormInput';
import FormLabel from '../forms/controls/FormLabel';
import SocialLoginButtons from '@c/SocialLoginButton';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import { pushToDataLayer } from '@util/analytics';

const EmailPasswordLoginForm = ({
  switchToPasswordlessTab,
  switchToSignupTab
}: {
  switchToPasswordlessTab: () => void;
  switchToSignupTab: () => void;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { loginEmailPassword, loginSocial, isLoggingIn, fetchProviderForEmail } = useAuth();

  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formSchema = z.object({
    email: emailSchema,
    password: passwordSchema,
  });

  type Form = z.infer<typeof formSchema>;

  const { register, handleSubmit, formState, watch } = useForm<Form>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const form = watch();
  const errors = formState.errors;

  const login: SubmitHandler<Form> = async () => {
    setIsSubmitting(true);
    const loginResp = await loginEmailPassword(form);

    if (loginResp instanceof FirebaseError) {
      if (loginResp.code === 'auth/wrong-password') {
        const provider = await fetchProviderForEmail(form.email);
        if (provider) {
          setAuthError(
            new FirebaseError('auth/wrong-provider', `This account was signed up with ${provider}. Please sign in with that provider.`)
          );
          setIsSubmitting(false);
          return;
        }
      }
      setIsSubmitting(false);
      setAuthError(loginResp);
    } else {
      router.push(router.query?.redirect?.toString() || '/dashboard/account-overview');
    }
  };

  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);

    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(res);
    } else {
      if (res?.user?.uid) {
        const userDoc = await getUserById(res.user.uid);
        if (!userDoc) {
          const docData = getValidUserDocument(
            res.user.uid,
            res.user.email ?? '',
            {
              firstName: res.user.displayName ? res.user.displayName.split(' ')[0] : 'Anonymous',
              lastName: res.user.displayName?.split(' ').pop() ?? 'User',
            }
          );
          await createUserDocument(docData, s);

          // track signup event
          pushToDataLayer('sign_up', {
            method: s,
          });

          queryClient.invalidateQueries(['user']);
        }
        router.push(router.query?.redirect?.toString() || '/dashboard/account-overview');
      } else {
        setIsSubmitting(false);
        setAuthError(new FirebaseError('auth/unknown', 'Unknown error'));
      }
    }
  };

  return (
    <div className="w-full max-w-[36rem] mb-[4rem] lg:mb-[6rem]">
      <h1 className="text-[2.4rem] font-semibold">Log in to GearFocus</h1>
      <div className="flex w-full text-[1.3rem]">
        <p>
          Don&apos;t have an account?&nbsp;
          <button onClick={switchToSignupTab} className="font-semibold text-brand-secondary">
            Sign Up
          </button>
        </p>
      </div>

      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        {authError && (
          <div className="absolute -top-10 w-full">
            <div className="m-auto w-fit justify-center rounded-[10px] border bg-red-200 px-[1.6rem] py-[0.8rem] font-medium text-red-900">
              {formatAuthError(authError)}
            </div>
          </div>
        )}

        <form className="flex flex-col gap-[2rem]" onSubmit={handleSubmit(login)}>
          <FormLabel value="Email" errorMessage={errors.email?.message}>
            <FormInput
              type="email"
              placeholder="Enter your email"
              error={!!errors.email}
              {...register('email')}
              autoComplete="email"
            />
          </FormLabel>

          <FormLabel value="Password" errorMessage={errors.password?.message}>
            <FormInput
              type="password"
              placeholder="Enter your password"
              error={!!errors.password}
              {...register('password')}
              autoComplete="current-password"
            />
          </FormLabel>

          <div className="flex w-full flex-row-reverse text-input font-semibold text-brand-secondary">
            <Link href="/forgot-password" className="select-none focus:border-none focus:outline focus:outline-1">
              Forgot Password?
            </Link>
          </div>

          <Button text="Log in" type="secondary" loading={isLoggingIn || isSubmitting} buttonType="submit" />
        </form>

        <div className="flex h-[2.4rem] justify-between">
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
          <p>or continue with</p>
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
        </div>

        <SocialLoginButtons handleSocialClick={handleSocialClick} />

        <div className="mt-[2.4rem] flex w-full text-[1.3rem] lg:mt-[3.2rem]">
          <p>
            Use a&nbsp;
            <button onClick={switchToPasswordlessTab} className="font-semibold text-brand-secondary">
              secure email link
            </button>
            &nbsp;instead of a password.
          </p>
        </div>
      </div>

      <p className="mt-[2.4rem] text-tos lg:mt-[3.2rem]">
        By clicking Log in or Continue with Facebook, Apple, or Google, you agree to GearFocus&apos;s&nbsp;
        <LinkTargetBlank href="/terms-and-conditions">Terms of Service</LinkTargetBlank>
        &nbsp;and&nbsp;
        <LinkTargetBlank href="/privacy-policy">Privacy Policy</LinkTargetBlank>.
      </p>
    </div>
  );
};

export default EmailPasswordLoginForm;