import { CheckmarkIcon } from '@c/icons';
import CheckmarkIconButton from '@c/icons/buttons/CheckmarkButton';
import SocialLoginButtons from '@c/SocialLoginButton';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import {
  createUserDocument,
  emailSchema,
  getUserById,
  getValidUserDocument,
  passwordSchema
} from '@util/firestore/users';
import { formatAuthError } from '@util/index';
import { cx } from 'class-variance-authority';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import FormInput from '../forms/controls/FormInput';
import FormLabel from '../forms/controls/FormLabel';

import { ActiveCheckIcon } from '@c/icons/ActiveCheckIcon';
import { pushToDataLayer } from '@util/analytics';

const EmailPasswordSignupForm = ({
  continueFn,
  switchToPasswordlessTab,
  switchToPasswordTab
}: {
  continueFn: (uid: string, firstName: string, lastName: string, isSocial: boolean) => void;
  switchToPasswordlessTab: () => void;
  switchToPasswordTab: () => void;
}) => {
  const router = useRouter();
  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    loginSocial,
    createAccountEmailPassword,
    isLoggingIn,
    sendEmailVerification,
    fetchProviderForEmail,
  } = useAuth();

  const formSchema = z.object({
    email: emailSchema.superRefine(async (email, ctx) => {
      if (email?.includes('@') && email?.includes('.')) {
        const provider = await fetchProviderForEmail(email);
        if (provider)
          ctx.addIssue({
            code: 'custom',
            message: 'This email is already signed up with Gear Focus.',
          });
      }
    }),
    password: passwordSchema,
    consent: z
      .boolean()
      .refine((x) => !!x, 'must agree to ToS and Privacy Policy'),
    firstName: z
      .string()
      .min(2, 'First name must be at least 2 characters')
      .regex(/^[a-zA-Z ]+$/),
    lastName: z
      .string()
      .min(2, 'Last name must be at least 2 characters')
      .regex(/^[a-zA-Z ]+$/),
  });

  type Form = z.infer<typeof formSchema>;

  const { register, setValue, handleSubmit, formState, watch } = useForm<Form>({
    defaultValues: {
      password: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const form = watch();
  const errors = formState.errors;

  const signup: SubmitHandler<Form> = async () => {
    setIsSubmitting(true);
    form.email = form.email.toLowerCase().trim();
    const createResp = await createAccountEmailPassword(form);
    if (createResp instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(createResp);
    } else if (
      createResp.user &&
      createResp.user.uid &&
      createResp.user.email
    ) {
      const docData = getValidUserDocument(
        createResp.user.uid,
        createResp.user.email,
        form
      );
      await Promise.allSettled([
        createUserDocument(docData, 'email'),
        sendEmailVerification(),
      ]);

      // track signup event
      pushToDataLayer('sign_up', {
        method: 'email',
      });

      continueFn(createResp.user.uid, form.firstName, form.lastName, false);
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      setAuthError(new FirebaseError('auth/unknown', 'unknown error'));
    }
  };

  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);
    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(res);
    } else {
      if (res?.user?.uid) {
        const userDoc = await getUserById(res.user.uid);
        if (!userDoc) {
          const docData = getValidUserDocument(
            res.user.uid,
            res.user.email ?? '',
            {
              firstName: res.user.displayName
                ? res.user.displayName.split(' ')[0]
                : 'Anonymous',
              lastName: res.user.displayName?.split(' ').pop() ?? 'User',
            }
          );
          await createUserDocument(docData, s);

          // track signup event
          pushToDataLayer('sign_up', {
            method: s,
          });

          continueFn(
            res.user.uid,
            res.user.displayName?.split(' ')?.[0] ?? '',
            res.user.displayName?.split(' ')?.[1] ?? '',
            true
          );
        } else router.push((router.query.redirect as string) ?? '/');
      } else {
        setIsSubmitting(false);
        setAuthError(new FirebaseError('auth/unknown', 'unknown error'));
      }
    }
  };

  return (
    <div className="w-full max-w-[36rem] mb-[4rem] lg:mb-[6rem]">
      <h1 className="text-[2.4rem] font-semibold">Sign up to GearFocus</h1>
      <h2 className="mt-[0.4rem] text-[1.3rem]">
        Create your account to like, purchase or chat with the seller.
      </h2>
      <div className="mt-[1rem] flex w-full  text-[1.3rem]">
        <p>
          Use a&nbsp;
          <button onClick={switchToPasswordlessTab} className="font-semibold text-brand-secondary">
            secure email link
          </button>
          &nbsp;instead of a password.
        </p>
      </div>
      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        {authError && (
          <div className="absolute -top-10 w-full">
            <div className="m-auto w-fit justify-center rounded-[10px] border bg-red-200 px-[1.6rem] py-[0.8rem] font-medium text-red-900">
              {formatAuthError(authError)}
            </div>
          </div>
        )}
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={handleSubmit(signup)}
        >
          {/* email */}
          <FormLabel
            value="First Name"
            errorMessage={errors.firstName?.message}
          >
            <FormInput
              placeholder="First name"
              error={!!errors.firstName}
              {...register('firstName')}
              autoComplete="given-name"
            />
          </FormLabel>
          <FormLabel value="Last Name" errorMessage={errors.lastName?.message}>
            <FormInput
              placeholder="Last name"
              error={!!errors.lastName}
              {...register('lastName')}
              autoComplete="family-name"
            />
          </FormLabel>
          <FormLabel value="Email" errorMessage={errors?.email?.message}>
            <FormInput
              type="email"
              placeholder="Enter your email"
              error={!!errors.email}
              {...register('email')}
              autoComplete="email"
            />
          </FormLabel>

          {/* password */}
          <FormLabel value="Password">
            <FormInput
              type="password"
              placeholder="Enter your password"
              error={!!errors.password}
              {...register('password')}
              autoComplete="new-password"
            />
            <div className="mt-[0.8rem] flex flex-col gap-[0.8rem] text-[1.3rem]">
              <div className="flex items-center gap-[0.6rem]">
                <ActiveCheckIcon active={form.password.length >= 8} />
                <p>8 or more characters</p>
              </div>
              <div className="flex items-center gap-[0.6rem]">
                <ActiveCheckIcon
                  active={
                    !!form.password.match(/[a-z]/) &&
                    !!form.password.match(/[A-Z]/)
                  }
                />
                <p>Upper & lowercase letters</p>
              </div>
              <div className="flex items-center gap-[0.6rem]">
                <ActiveCheckIcon active={!!form.password.match(/[0-9]/)} />
                <p>At least one number</p>
              </div>
            </div>
          </FormLabel>

          {/* agree to TOS & Privacy Policy */}
          <div
            className={cx([
              'flex h-[2.4rem] items-center gap-[0.8rem]',
              !!errors.consent ? 'rounded-sm border-2 border-red-500' : '',
            ])}
          >
            <CheckmarkIconButton
              onClick={() => setValue('consent', !form.consent)}
              checked={form.consent}
            />
            <p className="text-tos">
              I agree to&nbsp;
              <LinkTargetBlank href="/terms-and-conditions">
                Terms of Service
              </LinkTargetBlank>
              &nbsp;and&nbsp;
              <LinkTargetBlank href="/privacy-policy">
                Privacy Policy
              </LinkTargetBlank>
              .
            </p>
          </div>
          {/* login button */}
          <Button
            text={'Sign up'}
            type="secondary"
            //disabled={!form.firstName || !form.lastName || !form.email || !form.password || !form.consent}
            loading={isLoggingIn || isSubmitting}
            buttonType="submit"
          />
        </form>
        {/* divider */}
        <div className="flex h-[2.4rem] justify-between">
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
          <p>or continue with</p>
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
        </div>

        <SocialLoginButtons handleSocialClick={handleSocialClick} />
      </div>
    </div>
  );
};

export default EmailPasswordSignupForm;
